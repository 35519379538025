import { addons } from '@storybook/addons';
import { create } from '@storybook/theming';

addons.setConfig({
  theme: create({
    base: 'light',
    brandTitle: 'nPlan',
    brandImage: 'https://uploads-ssl.webflow.com/5e662485415f1e0e1e4e57ac/5eda1d8181168b64168519cc_nPlanlogoblue-01.svg',
    brandTarget: '_self',
  }),
});